module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b4f4664ab7b41c3e528a9458912c3571"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://blog-admin.pedidosya.com.ve/graphql","type":{"Comment":{"exclude":true},"MediaItem":{"localFile":{"requestConcurrency":5,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"createFileNodes":true,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"ContentType":{"exclude":true},"MenuItem":{"exclude":true},"Menu":{"exclude":true},"Taxonomy":{"exclude":true},"UserRole":{"exclude":true},"PostFormat":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"timeout":1000000,"perPage":5,"requestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"html":{"createStaticFiles":false,"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/node_modules/@ntnhan30dh/gatsby-theme-wp-parent/src/intl","languages":["es","en"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/@ntnhan30dh/gatsby-theme-wp-parent/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://blog-admin.pedidosya.com.ve/graphql","siteMetadata":{"title":"Pedidosya Blog","siteUrl":"https://blog.pedidosya.com.ve/"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
